import { jsxs as e, jsx as n } from "react/jsx-runtime";
import { AppProvider as f, CustomizationProvider as m } from "@bushelpowered/bushel-web-offers";
import { OfferListPage as a } from "@bushelpowered/bushel-web-offers/pages";
import { u as p } from "../useFrontEndProps-COQpXMeD.js";
function h(o) {
  const { onToast: r, toastContainer: s, ...t } = p(o), i = !o.token || o.token && t.token;
  return /* @__PURE__ */ e(f, { ...t, children: [
    /* @__PURE__ */ n(m, { onToast: r, children: i && /* @__PURE__ */ n(a, {}) }),
    s
  ] });
}
export {
  h as OffersFrontEnd
};
